<template>
  <div class="modal modal-center">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>Terms and Disclose</h3>
        <button class="btn btn-control" @click="$emit('close')">
          <img src="@/assets/icons/icon-remove.svg" alt="">
        </button>
      </div>
      <div class="modal-body pb-0">
        <div class="modal-body-container border-bottom-0">
          <p>
            You are offering a <u>{{ option.term }}-year fixed</u>, for
            <u>{{ rate }}%</u>
            with a
            <u>{{ isCredit ? `(${convertToCurrency(finalPrice)}) credit.` : `${convertToCurrency(finalPrice)} discount fee.` }}</u>

            <svg class="ms-2" width="14" height="14" xmlns="http://www.w3.org/2000/svg"
                 content="Description of what you are viewing"
                 v-tippy="{placement: 'right', arrow: true, arrowType: 'round'}">
              <path d="M7 .5a6.5 6.5 0 100 13 6.5 6.5 0 000-13zM5.625 5.625h2.75V11h-2.75V5.625zm2.75-3.5v2.75h-2.75v-2.75h2.75z"
                fill="#000" stroke="#000" fill-rule="evenodd" opacity=".2"></path>
            </svg>
          </p>
          <p class="wire-instruction__text">
            <b>Origination:</b>
            <svg class="ms-2" width="14" height="14" xmlns="http://www.w3.org/2000/svg"
                 content="A Breakdown of all origination fees"
                 v-tippy="{placement: 'right', arrow: true, arrowType: 'round'}">
              <path d="M7 .5a6.5 6.5 0 100 13 6.5 6.5 0 000-13zM5.625 5.625h2.75V11h-2.75V5.625zm2.75-3.5v2.75h-2.75v-2.75h2.75z"
                    fill="#000" stroke="#000" fill-rule="evenodd" opacity=".2"></path>
            </svg>
            <br>
            Underwriting Fee: $1,395.00<br>
            <span v-if="isCredit">Lender Credit: {{ `(${convertToCurrency(finalPrice)})` }}</span>
            <span v-else>Discount Fee: {{ `${convertToCurrency(finalPrice)}` }}</span>
            <br>
            <span v-if="isCredit">
              <span v-if="adjustedOriginalCharge < 0">
                Adjusted Origination Charge: ({{ convertToCurrency(adjustedOriginalCharge) }})
              </span>
              <span v-else>
                Adjusted Origination Charge: {{ convertToCurrency(adjustedOriginalCharge) }}
              </span>
            </span>
            <span v-else>
              Adjusted Origination Charge: {{ convertToCurrency(finalPrice + 1395) }}
            </span>
          </p>
          <p class="wire-instruction__text" v-if="isCredit">
            The fees will look like this on the Loan Estimate:
          </p>
          <p class="wire-instruction__text">
            <b>Section A:</b>
            <svg class="ms-2" width="14" height="14" xmlns="http://www.w3.org/2000/svg"
                 content="Put these items in Section A"
                 v-tippy="{placement: 'right', arrow: true, arrowType: 'round'}">
              <path d="M7 .5a6.5 6.5 0 100 13 6.5 6.5 0 000-13zM5.625 5.625h2.75V11h-2.75V5.625zm2.75-3.5v2.75h-2.75v-2.75h2.75z"
                    fill="#000" stroke="#000" fill-rule="evenodd" opacity=".2"></path>
            </svg>
            <br>
            <span v-if="!isCredit">
              Correspondent Discount Fee: {{ convertToCurrency(finalPrice) }} ({{ Number.parseFloat((finalPrice / loanAmount) * 100).toFixed(5) }})<br>
            </span>
            Underwriting Fee: $1,395.00<br>
            Wire Fee: $47.00<br>
            <span v-if="isCredit">
              Correspondent Lender Credit: {{ convertToCurrency(finalPrice) }}
            </span>
          </p>
          <p class="wire-instruction__text">
            <b>Section B:</b>
            <svg class="ms-2" width="14" height="14" xmlns="http://www.w3.org/2000/svg"
                 content="Put these items in Section B"
                 v-tippy="{placement: 'right', arrow: true, arrowType: 'round'}">
              <path d="M7 .5a6.5 6.5 0 100 13 6.5 6.5 0 000-13zM5.625 5.625h2.75V11h-2.75V5.625zm2.75-3.5v2.75h-2.75v-2.75h2.75z"
                    fill="#000" stroke="#000" fill-rule="evenodd" opacity=".2"></path>
            </svg>
            <br>
            Flood Certificate: $8.00<br>
            Tax Service: $85.00<br>
            <span v-if="isGranted('ROLE_SD_COMP_PLAN')">Third Party Processing: $1990</span>
            <span v-if="isCondo">HOA Fee: $250.00<br></span>
            <span v-if="loanType === 'VA'">Pest Inspection: $300.00<br></span>
            <span v-if="loanType === 'VA' && state === 'TX'">Survey Fee: $600.00<br></span>
          </p>
          <p class="wire-instruction__text">
            <b>Section C + D:</b>
            <svg class="ms-2" width="14" height="14" xmlns="http://www.w3.org/2000/svg"
                 content="Put section C and D according to settlement fees"
                 v-tippy="{placement: 'right', arrow: true, arrowType: 'round'}">
              <path d="M7 .5a6.5 6.5 0 100 13 6.5 6.5 0 000-13zM5.625 5.625h2.75V11h-2.75V5.625zm2.75-3.5v2.75h-2.75v-2.75h2.75z"
                    fill="#000" stroke="#000" fill-rule="evenodd" opacity=".2"></path>
            </svg>
            <br>
            Third Party fees<br>
          </p>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-cancel" @click="$emit('close')">
            Close
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TermsAndDiscloseModal',
  props: {
    option: { type: Object },
    rate: { type: String },
    isCondo: { type: Boolean },
    loanAmount: { type: Number },
    loanType: {type: String},
    state: {type: String},
    isVA: {type: Boolean},
  },
  data() {
    return {
      isCredit: false,
      finalPrice: 0.00,
      adjustedOriginalCharge: 0.00,
    }
  },
  mounted() {
    let option = this.option
    this.isCredit = option.final_price < 0
    this.finalPrice = Math.abs(option.final_price)
    this.adjustedOriginalCharge = Number.parseFloat(option.final_price + 1395).toFixed(2)
  }
}
</script>

<style lang="scss" scoped>
.modal {
  &-block {
    height: fit-content!important;
    margin: 150px auto!important;
    overflow-y: hidden !important;

    p {
      color: #000000;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 25px;
    }
    .modal-footer {
      bottom: unset;
    }
  }
}
</style>
