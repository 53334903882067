<template>
  <div>
    <div v-for="(program, programKey) in programs" :key="programKey">
      <div class="col-12" @click="toggleProgramOptions(programKey)" v-if="program.visible">
        <div :class="{active: program.showOptions}" class="tab-content">
          <div class="row">
            <div class="col-11">
              <div class="tab-content-title">
                {{ program.program }}
                <span class="mi" v-if="program.mortgageInsurance">{{ program.mortgageInsurance }}</span>
              </div>
              <div class="mi-desc" v-if="program.mortgageInsurance === 'BORROWER PAID MI'">
                BPMI (Monthly) quotes assume a DTI less than or equal to 45%. Please reference the MI rate card for factors
              </div>
              <div class="tab-content-subtitle">
                {{ program.lock }} days lock period
              </div>
            </div>
            <div class="col-1 my-auto d-flex justify-content-end">
              <img alt="" src="@/assets/icons/arrow-down.svg">
            </div>
          </div>
        </div>
      </div>
      <transition name="fade">
        <div v-if="program.showOptions" class="col-12">
          <div class="loan-term-info">
            <div class="row loan-term-info-header">
              <div class="col-1 p-0">
                <div class="title">Rate</div>
              </div>
              <div class="col-1 p-0" v-if="!isGranted('ROLE_IREFI_AGENT')">
                <div class="title">Base Price</div>
              </div>
              <div class="col-3 px-5">
                <div class="title">Final price</div>
                <div class="subtitle">Rate / Amount</div>
              </div>
              <div class="col-2">
                <div class="title">P & I</div>
              </div>
              <div class="col-2 p-0">
                <div class="title">MI</div>
                <div class="subtitle">Factor / Amount</div>
              </div>
              <div class="col-1 p-0">
                <div class="title">Payment</div>
              </div>
              <div class="col-2 p-0">
                <div class="title"></div>
              </div>
            </div>
            <div class="row loan-term-info-body" v-for="(option, index) in program.options" :key="index">
              <div class="col-1 p-0">
                <div class="title color-blue">{{ index }}%</div>
              </div>
              <div class="col-1 p-0" v-if="!isGranted('ROLE_IREFI_AGENT')">
                <div class="title color-blue">{{ formatRate(option.base_price) }}</div>
              </div>
              <div class="col-3 px-5">
                <span class="subtitle">{{ option.final_price_percent }}% / </span>
                <span class="subtitle">${{ option.final_price_amount }}</span>
              </div>
              <div class="col-2">
                <div class="title color-blue">{{ convertToCurrency(option.pi) }}</div>
              </div>
              <div class="col-2 p-0">
                <span class="subtitle">{{ option.miFactor }} / </span>
                <span class="subtitle">${{ option.miAmount }}</span>
              </div>
              <div class="col-1 p-0">
                <div class="title color-blue">{{ convertToCurrency(option.payment) }}</div>
              </div>
              <div class="col-2 p-0 d-flex justify-content-end">
                <div class="btn-group">
                  <button class="title font-normal cursor-pointer btn btn-outline-dark"
                          @click="openTermAndDiscloseModal(option, index, program)">
                    T&D
                  </button>
                  <button class="title font-normal cursor-pointer btn btn-outline-dark"
                          @click="openAdjustmentsModal(program.breakdown, option.base_price, program.loan_amount, index, program.fundingFee, program.fundingFeeType)">
                    Adj
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <TermsAndDiscloseModal v-if="reviewTermsAndDisclose"
                           :option="selectedOption"
                           :rate="selectedRate"
                           :is-condo="isCondo"
                           :loan-amount="selectedLoanAmount"
                           :loan-type="selectedLoanType"
                           :state="state"
                           :isVA="isVA"
                           @close="closeTermAndDiscloseModal"/>
    <AdjustmentsModal v-if="showAdjustments"
                      :breakdown="selectedBreakdown"
                      :base-price="selectedBasePrice"
                      :loan-amount="selectedLoanAmount"
                      :interest-rate="selectedInterestRate"
                      :funding-fee="selectedFundingFee"
                      :funding-fee-type="selectedFundingFeeType"
                      @close="closeAdjustmentsModal"/>
  </div>
</template>

<script>
import TermsAndDiscloseModal from "@/views/corrPricer/modals/TermsAndDiscloseModal";
import AdjustmentsModal from "@/views/corrPricer/modals/AdjustmentsModal";
export default {
  name: 'PricerConfigTab',
  components: {TermsAndDiscloseModal, AdjustmentsModal},
  props: {
    tabData: { required: true },
    loanTerm: { required: false },
    lockTerm: { required: false },
    propertyType: { required: true },
    state: { required: true },
    isVA: { required: true },
  },
  data() {
    return {
      programs: this.tabData,
      reviewTermsAndDisclose: false,
      showAdjustments: false,
      selectedOption: null,
      selectedRate: null,
      selectedBreakdown: null,
      selectedBasePrice: null,
      selectedLoanType: null,
      selectedLoanAmount: null,
      selectedInterestRate: null,
      selectedFundingFee: null,
      selectedFundingFeeType: null
    }
  },
  methods: {
    toggleProgramOptions(index) {
      let program = this.programs[index]
      if (typeof program === 'object' && Object.prototype.hasOwnProperty.call(program, 'showOptions')) {
        program.showOptions = !program.showOptions
      } else {
        program.showOptions = false
      }
    },
    updateFilter() {
      let visibleProgramsNumber = 0
      for (let programKey in this.programs) {
        if (Object.prototype.hasOwnProperty.call(this.programs, programKey)) {
          let visible = true

          if (this.loanTerm.length > 0 && !this.loanTerm.includes(this.programs[programKey].term)) {
            visible = false
          }

          // if (this.lockTerm.length > 0 && !this.lockTerm.includes(this.programs[programKey].lock)) {
          //   visible = false
          // }

          this.programs[programKey]['visible'] = visible

          if (visible === true) {
            visibleProgramsNumber++
          }
        }
      }

      if (visibleProgramsNumber === 1) {
        for (let programKey in this.programs) {
          if (Object.prototype.hasOwnProperty.call(this.programs, programKey)) {
            if (this.programs[programKey].visible === true) {
              this.programs[programKey]['showOptions'] = true
            }
          }
        }
      }
    },
    openTermAndDiscloseModal(option, rate, program) {
      this.reviewTermsAndDisclose = true
      this.selectedOption = option
      this.selectedRate = rate
      this.selectedLoanType = program.loanParams['Loan Type']
      this.selectedLoanAmount = program.loan_amount
    },
    closeTermAndDiscloseModal() {
      this.reviewTermsAndDisclose = false
      this.selectedOption = null
      this.selectedRate = null
      this.selectedLoanType = null
      this.selectedLoanAmount = 0.00
    },
    openAdjustmentsModal(adjustments, basePrice, loanAmount, rate, fundingFee, fundingFeeType) {
      this.showAdjustments = true
      this.selectedBreakdown = adjustments
      this.selectedBasePrice = basePrice
      this.selectedLoanAmount = loanAmount
      this.selectedInterestRate = rate
      this.selectedFundingFee = fundingFee
      this.selectedFundingFeeType = fundingFeeType
    },
    closeAdjustmentsModal() {
      this.showAdjustments = false
      this.selectedBreakdown = null
      this.selectedBasePrice = null
      this.selectedLoanAmount = null
      this.selectedInterestRate = null
      this.selectedFundingFee = null
      this.selectedFundingFeeType = null
    }
  },
  watch: {
    loanTerm: function () {
      this.updateFilter()
    },
    lockTerm: function () {
      this.updateFilter()
    },
    tabData: function (newValue, oldValue) {
      if (newValue !== oldValue) {
       this.tabData = newValue
      }
    }
  },
  computed: {
    isCondo() {
      let type = this.propertyType;
      return (
        type === "Condominium" ||
        type === "Site Condo" ||
        type === "Manufactured Condo Single Wide" ||
        type === "Manufactured Condo Multi Wide"
      );
    }
  },
  mounted() {
    this.updateFilter()
  }
}
</script>

<style lang="scss" scoped>
.tab-content {
  padding: 15px 18px;
  transition: all .3s;
  cursor: pointer;
  border-bottom: 1px solid #E7E8E8;

  &:hover {
    background-color: rgba(0, 0, 0, .05);
  }

  &.active {
    background-color: rgba(0, 0, 0, .05);

    img {
      transform: rotate(180deg);
    }
  }

  img {
    transition: all .3s;
  }

  &-title {
    color: #000000;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 15px;
    margin-bottom: 10.5px;
  }

  &-subtitle {
    color: rgba(0, 0, 0, .6);
    font-size: 12px;
    letter-spacing: 0;
    line-height: 12px;
  }
}

.loan-term-info {
  &-header {
    .title {
      color: #000000;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 12px;
      margin-bottom: 9px;
    }

    .subtitle {
      color: rgba(0, 0, 0, .6);
      font-size: 12px;
      letter-spacing: 0;
      line-height: 12px;
    }
  }

  &-body {
    .title {
      color: #266FE5;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 15px;
      margin-bottom: 9px;

      &.color-blue {
        color: #266FE5;
      }

      &.color-green {
        color: #24A82F;
      }

      &.font-normal {
        color: #000000;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 15px;
      }
    }

    .subtitle {
      color: rgba(0, 0, 0, .6);
      font-size: 12px;
      letter-spacing: 0;
      line-height: 12px;

      .row {
        padding: 0;
        border: none;
      }
    }
  }

  .row {
    padding: 14px 24px;
    border-bottom: 1px solid #E7E8E8;
    width: 100%;
    margin: 0 auto;
    align-items: baseline;
  }
}

.mi {
  color: #f89420;
  font-weight: 700;
}
.mi-desc {
  color: #f89420;
  font-weight: 400;
}
</style>
