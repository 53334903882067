<template>
<div>
  <div>
    <!-- Page Header -->
    <div class="page-header d-flex justify-content-between align-items-center">
      <div>
        <h3 class="page-header-title">Correspondent Pricer</h3>
      </div>
      <div class="page-header-buttons d-flex">
        <div id="agentsField" v-if="isGranted('ROLE_CEO_ASSISTANT')">
          <multiselect open-direction="bottom" track-by="id" label="name"
                       v-model="selectedAgent"
                       :options="agentsPartners"
                       :searchable="true"
                       :show-labels="false"
                       @select="togglePartners"
                       v-if="isGranted('ROLE_CEO')"
                       placeholder="Select agent">
          </multiselect>
        </div>
        <base-button v-if="ui.branchId !== 14 || ui.branchId !== 17"
                     title="Get Quick Help"
                     action="primary"
                     content="Use this button if you need help with anything in the correspondent loan submission, process, COC, or general review."
                     v-tippy
                     class="me-2"
                     @click-btn="sendHelpRequest" />
        <base-button v-if="isGranted('ROLE_CEO_ASSISTANT')" title="Pricer configuration" action="primary" @click-btn="$router.push({ name: 'corr_pricing_config' })" />
      </div>
    </div>
  </div>

  <!-- Preload spinner -->
  <div class="text-center mt-4" v-if="ui.loading">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

  <!-- Page Content -->
  <div class="page-content" v-else>
    <div class="container-fluid">
      <div class="row">
        <!-- Loan Details Column -->
        <div class="col-md-4 p-0 pricer-col">
          <div class="pricer-col-title">
            <div class="row">
              <div class="col-6">Loan Details</div>
              <div class="col-6"></div>
            </div>
          </div>
          <div class="pricer-col-body">
            <div class="form-group row full-width input-row">
              <label for="purposeField" class="col-sm-4 col-form-label col-form-label-sm">Loan Type</label>
              <div class="col-sm-8 p-0">
                <multiselect
                  v-model="details.loanType"
                  id="loanTypeField"
                  class="full-width w-100 custom-select"
                  open-direction="bottom"
                  :options="multiSelectData.loanTypes"
                  :class="{ selected: Object.keys(details.purpose).length > 0, 'input-filled': details.purpose }"
                  :searchable="false"
                  :show-labels="false"
                  @select="toggleLoanType"
                  placeholder="">
                </multiselect>
              </div>
            </div>
            <div class="form-group row full-width input-row mt-3">
              <label for="purposeField" class="col-sm-4 col-form-label col-form-label-sm">Loan Purpose</label>
              <div class="col-sm-8 p-0">
                <multiselect
                  v-model="details.purpose"
                  id="purposeField"
                  class="full-width w-100 custom-select"
                  open-direction="bottom"
                  @select="changedData"
                  :options="multiSelectData.purposes"
                  :class="{ selected: Object.keys(details.purpose).length > 0, 'input-filled': details.purpose }"
                  :searchable="false"
                  :show-labels="false"
                  placeholder="">
                </multiselect>
              </div>
            </div>
            <div class="form-group row full-width input-row mt-3" v-if="showVAUseTypeField">
              <label for="purposeField" class="col-sm-4 col-form-label col-form-label-sm">VA Use Type</label>
              <div class="col-sm-8 p-0">
                <multiselect
                  v-model="details.VAUseType"
                  id="VAUseTypeField"
                  class="full-width w-100 custom-select"
                  track-by="value"
                  @select="changedData"
                  label="label"
                  open-direction="bottom"
                  :options="multiSelectData.VAUseTypeOptions"
                  :class="{ selected: Object.keys(details.VAUseType).length > 0, 'input-filled': details.VAUseType }"
                  :searchable="false"
                  :show-labels="false"
                  placeholder="VA Use Type">
                </multiselect>
                <div class="error" v-if="errors['VAUseType']">
                  {{ errors['VAUseType'][0] }}
                </div>
              </div>
            </div>
            <div class="form-group row full-width input-row mt-3" v-if="showFundingFeeInput">
              <label for="purposeField" class="col-sm-4 col-form-label col-form-label-sm">Funding Fee</label>
              <div class="col-sm-8 p-0">
                <multiselect
                  v-model="details.fundingFeeType"
                  id="fundingFeeTypeField"
                  class="full-width w-100 custom-select"
                  track-by="value"
                  label="label"
                  @select="changedData"
                  open-direction="bottom"
                  :options="multiSelectData.fundingFeeTypeOptions"
                  :class="{ selected: Object.keys(details.fundingFeeType).length > 0, 'input-filled': details.fundingFeeType }"
                  :searchable="false"
                  :show-labels="false"
                  placeholder="Funding Fee">
                </multiselect>
                <div class="error" v-if="errors['fundingFeeType']">
                  {{ errors['fundingFeeType'][0] }}
                </div>
              </div>
            </div>
            <div class="form-group row full-width input-row mt-3">
              <label for="loanAmountField" class="col-sm-4 col-form-label col-form-label-sm">Loan Amount</label>
              <div class="col-sm-8 p-0" ref="loanAmount">
                <input
                  type="text"
                  class="form-control"
                  id="loanAmountField"
                  placeholder="$300,000"
                  v-mask="mask"
                  v-model="details.loanAmount"
                  @change="changedData()"
                  @keyup="updateLTV($event, 'loanAmount')"
                  v-input-filled="details.loanAmount"/>
                <div class="error" v-if="errors['loanAmount']">
                  {{ errors['loanAmount'][0] }}
                </div>
              </div>
            </div>
            <div class="form-group row full-width input-row mt-3" v-if="details.purpose !== 'Purchase'">
              <label for="loanAmountField" class="col-sm-4 col-form-label col-form-label-sm">2nd Loan Amount</label>
              <div class="col-sm-8 p-0" ref="loanAmount">
                <input
                  type="text"
                  class="form-control"
                  id="secondLoanAmountField"
                  placeholder="$300,000"
                  v-model="details.secondLoanAmount"
                  v-mask="mask"
                  @change="changedData"
                  v-input-filled="details.secondLoanAmount"/>
                <div class="error" v-if="errors['secondLoanAmount']">
                  {{ errors['secondLoanAmount'][0] }}
                </div>
              </div>
            </div>
            <div class="form-group row full-width input-row mt-3">
              <label for="propertyValueField" class="col-sm-4 col-form-label col-form-label-sm">Property Value</label>
              <div class="col-sm-8 p-0">
                <input
                  type="text"
                  class="form-control"
                  id="propertyValueField"
                  placeholder="$450,000"
                  v-mask="mask"
                  @change="changedData()"
                  @keyup="updateLTV($event, 'propertyValue')"
                  v-model="details.propertyValue"
                  v-input-filled="details.propertyValue"
                />
                <div class="error" v-if="errors['propertyValue']">
                  {{ errors['propertyValue'][0] }}
                </div>
              </div>
            </div>
            <div class="form-group row full-width input-row mt-3">
              <label for="ficoField" class="col-sm-4 col-form-label col-form-label-sm">LTV</label>
              <div class="col-sm-3 p-0">
                <input
                  type="number"
                  class="form-control"
                  id="ltvField"
                  placeholder="75.00"
                  v-model="details.ltv"
                  @change="changedData()"
                  @keyup="updateLTV($event, 'ltv')"
                  v-input-filled="details.ltv"/>
                <div class="error" v-if="errors['ltv']">
                  {{ errors['ltv'][0] }}
                </div>
              </div>
              <div class="col-sm-5 col-form-label col-form-label-sm">
                <b>CLTV: {{ getCLTV }}</b>
              </div>
            </div>
            <div class="form-group row full-width input-row mt-3">
              <label for="ficoField" class="col-sm-4 col-form-label col-form-label-sm">FICO</label>
              <div class="col-sm-3 p-0">
                <input
                  type="number"
                  class="form-control"
                  id="ficoField"
                  placeholder="740"
                  v-model="details.fico"
                  @change="changedData()"
                  v-input-filled="details.fico"/>
                <div class="error" v-if="errors['creditScore']">
                  {{ errors['creditScore'][0] }}
                </div>
              </div>
            </div>
            <div class="form-group row full-width input-row mt-3">
              <label for="monthsOfReserves" class="col-sm-4 col-form-label col-form-label-sm">Months Of Reserves</label>
              <div class="col-sm-3 p-0">
                <input
                  type="number"
                  class="form-control"
                  id="monthsOfReserves"
                  placeholder="12"
                  v-model="details.monthsOfReserves"
                  @change="changedData()"
                  v-input-filled="details.monthsOfReserves"/>
                <div class="error" v-if="errors['monthsOfReserves']">
                  {{ errors['monthsOfReserves'][0] }}
                </div>
              </div>
            </div>
            <div class="form-group row full-width input-row mt-3" v-if="ltv > 80 && details.loanType !== 'VA'">
              <label for="propertyTypeField" class="col-sm-4 col-form-label col-form-label-sm">MI Type</label>
              <div class="col-sm-8 p-0">
                <multiselect
                  v-model="details.mortgageInsuranceType"
                  id="miOptionsField"
                  class="full-width w-100 custom-select"
                  open-direction="bottom"
                  @select="changedData"
                  :options="multiSelectData.miTypeOptions"
                  :searchable="false"
                  :show-labels="false"
                  placeholder="">
                </multiselect>
                <div class="error" v-if="errors['mortgageInsuranceType']">
                  {{ errors['mortgageInsuranceType'][0] }}
                </div>
              </div>
            </div>
            <div class="form-group row full-width input-row mt-3">
              <label for="propertyTypeField" class="col-sm-4 col-form-label col-form-label-sm">Property Type</label>
              <div class="col-sm-8 p-0">
                <multiselect
                  v-model="details.propertyType"
                  id="propertyTypeField"
                  class="full-width w-100 custom-select"
                  open-direction="bottom"
                  @select="changedData"
                  :class="{ 'input-filled': details.propertyType }"
                  :options="multiSelectData.propertyTypes"
                  :searchable="false"
                  :show-labels="false"
                  placeholder="">
                </multiselect>
              </div>
            </div>
            <div class="form-group row full-width input-row mt-3" v-if="showAttachmentType">
              <label for="attachmentTypeField" class="col-sm-4 col-form-label col-form-label-sm">Attachment Type</label>
              <div class="col-sm-8 p-0">
                <multiselect
                  v-model="details.attachmentType"
                  id="attachmentTypeField"
                  class="full-width w-100 custom-select"
                  open-direction="bottom"
                  @select="changedData"
                  :class="{ selected: Object.keys(details.propertyType).length > 0, 'input-filled': details.attachmentType }"
                  :options="multiSelectData.attachmentTypes"
                  :searchable="false"
                  :show-labels="false"
                  placeholder="">
                </multiselect>
                <div class="error" v-if="errors['attachmentType']">
                  {{ errors['attachmentType'][0] }}
                </div>
              </div>
            </div>
            <div class="form-group row full-width input-row mt-3">
              <label for="ficoField" class="col-sm-4 col-form-label col-form-label-sm">Number of Units</label>
              <div class="col-sm-5 p-0">
                <input type="number" class="form-control" id="numberOfUnitsField" @change="changedData"
                       placeholder="2" min="1" max="4" @input="errors.numberOfUnits = ''"
                       v-model="details.numberOfUnits" v-input-filled="details.numberOfUnits"/>
                <div class="error" v-if="errors['numberOfUnits']">
                  {{ errors['numberOfUnits'][0] }}
                </div>
              </div>
            </div>
            <div class="form-group row full-width input-row mt-3">
              <label for="occupancyTypeField" class="col-sm-4 col-form-label col-form-label-sm">Occupancy Type</label>
              <div class="col-sm-8 p-0">
                <multiselect v-model="details.occupancyType" id="occupancyTypeField"
                             class="full-width w-100 custom-select"
                             open-direction="bottom"
                             @select="changedData"
                             :class="{ selected: Object.keys(details.occupancyType).length > 0, 'input-filled': details.occupancyType }"
                             :options="multiSelectData.occupancyTypes" :searchable="false" :show-labels="false"
                             placeholder="">
                </multiselect>
              </div>
            </div>
            <div class="form-group row full-width mt-3">
              <label for="zipField" class="col-sm-4 col-form-label col-form-label-sm">Zip</label>
              <div class="col-sm-5 p-0">
                <input type="number" class="form-control w-100" placeholder="92618" v-model="details.zipCode" @change="changedData"
                       v-input-filled="details.zipCode" id="zipField" @input="getState"/>
                <div class="error" v-if="errors['zipCode']">
                  {{ errors['zipCode'][0] }}
                </div>
                <span class="error" v-if="errorMsg">{{ errorMsg }}</span>
              </div>
              <div v-if="ui.loadingState" class="spinner-border" role="status" style="margin-left: 15px">
                <span class="visually-hidden" style="width: 1rem; height: 1rem">Loading...</span>
              </div>
            </div>
            <div class="form-group row full-width input-row mt-3">
              <label for="stateField" class="col-sm-4 col-form-label col-form-label-sm">State</label>
              <div class="col-sm-5 p-0">
                <input type="text" class="form-control w-100" placeholder="CA" @change="changedData"
                       v-model="details.state"
                       v-input-filled="details.state"
                       id="stateField"/>
                <div class="error" v-if="errors['propertyState']">
                  {{ errors['propertyState'][0] }}
                </div>
              </div>
            </div>
            <div class="form-group row full-width input-row mt-3">
              <label for="propertyTypeField" class="col-sm-4 col-form-label col-form-label-sm">County</label>
              <div class="col-sm-8 p-0">
                <multiselect
                  v-model="details.county"
                  id="countyField"
                  class="full-width w-100 custom-select"
                  open-direction="bottom"
                  @select="changedData"
                  :class="{ 'input-filled': details.county }"
                  :options="multiSelectData.countyOptions"
                  :searchable="false"
                  :show-labels="false"
                  placeholder="">
                </multiselect>
              </div>
            </div>
            <div class="form-group row full-width input-row mt-3">
              <label for="propertyTypeField" class="col-sm-4 col-form-label col-form-label-sm">Comm. Period</label>
              <div class="col-sm-8 p-0">
                <multiselect track-by="value" label="label"
                  v-model="details.commitmentPeriod"
                  id="countyField"
                  class="full-width w-100 custom-select"
                  open-direction="bottom"
                  @select="changedData"
                  :class="{ 'input-filled': details.commitmentPeriod }"
                  :options="lockPeriod"
                  :searchable="false"
                  :show-labels="false"
                  placeholder="">
                </multiselect>
              </div>
            </div>
            <div class="form-group row full-width input-row mt-3">
              <label for="propertyTypeField" class="col-sm-4 col-form-label col-form-label-sm">DTI > 40%</label>
              <div class="col-sm-8 p-0">
                <multiselect v-model="details.debtToIncome"
                             id="countyField"
                             class="full-width w-100 custom-select"
                             open-direction="bottom"
                             @select="changedData"
                             :class="{ 'input-filled': details.debtToIncome }"
                             :options="details.debtToIncomeOptions"
                             :searchable="false"
                             :show-labels="false"
                             placeholder="">
                </multiselect>
                <div class="error" v-if="errors['debtToIncome']">
                  {{ errors['debtToIncome'][0] }}
                </div>
              </div>
            </div>
            <div class="form-group row full-width input-row mt-3">
              <label for="propertyTypeField" class="col-sm-4 col-form-label col-form-label-sm">Exact Rate Type</label>
              <div class="col-sm-8 p-0">
                <multiselect v-model="details.exactRateType"
                             id="countyField"
                             class="full-width w-100 custom-select"
                             open-direction="bottom"
                             @select="changedData"
                             :class="{ 'input-filled': details.exactRateType }"
                             :options="details.exactRateTypeOptions"
                             placeholder="">
                </multiselect>
              </div>
            </div>
            <div class="form-group row full-width input-row mt-3" v-if="details.exactRateType === 'Target Rate'">
              <label for="ficoField" class="col-sm-4 col-form-label col-form-label-sm">Target Rate</label>
              <div class="col-sm-3 p-0">
                <input type="number"
                       class="form-control"
                       id="ltvField"
                       placeholder="6.100"
                       v-model="details.targetRate"
                       @change="changedData()"
                       v-input-filled="details.targetRate"/>
                <div class="error" v-if="errors['targetRate']">
                  {{ errors['targetRate'][0] }}
                </div>
              </div>
            </div>
            <div class="form-group row full-width input-row mt-3">
              <label for="bps" class="col-sm-4 col-form-label col-form-label-sm">CYP (BPS)</label>
              <div class="col-sm-8 p-0">
                <input type="number"
                       class="form-control"
                       id="bps"
                       v-model="details.bps"
                       v-input-filled="details.bps"/>
                <div class="error" v-if="errors['bps']">
                  {{ errors['bps'][0] }}
                </div>
              </div>
            </div>

            <div class="row full-width input-row mt-3 w-100">
              <div class="col-sm-4 form-group ">
                <div class="form-check-square d-flex align-items-center">
                  <label for="fieldIsFirstTimeHomeBuyer" class="mb-0">First Time Home Buyer</label>
                </div>
              </div>
              <div class="col-sm-8 p-0">
                <div class="form-check-square d-flex align-items-center">
                  <input type="checkbox" class="form-check-input" v-model="details.isFirstTimeHomeBuyer" id="fieldIsFirstTimeHomeBuyer">
                </div>
              </div>
            </div>

            <div class="form-group row full-width input-row mt-3">
              <div class="col-sm-4"></div>
              <div class="col-sm-8 p-0">
                <base-button title="Price" @click-btn="getQuote" action="primary" class="w-100" />
              </div>
            </div>
            <div class="form-group row full-width">
              <div class="alert alert-info mt-4" role="alert" v-if="details.isFirstTimeHomeBuyer">
                Note: Income must be 100% of AMI or 120% of AMI in high cost areas in order to qualify for for FTHB waiver.
              </div>
            </div>
          </div>
        </div>

        <!-- Filter and bank proposals info column -->
        <div class="col-md-8 p-0">
          <!-- Filter rows -->
          <div class="row w-100 m-auto border-bottom-light pb-3 pb-sm-0">
            <div class="col-sm-2 p-0">
              <div class="pricer-col-title border-0">Filter</div>
            </div>
            <div class="col-sm-10 my-auto">
              <div class="row">
                <div class="col-sm-4" id="table-filter" parent>
                  <div class="tippy-dropdown" name="tableFilter">
                      <span class="report-type">
                        Loan Term:
                        <span v-for="termSelected in loanTerms" :key="'Selected' + termSelected.id">
                          <span v-if="termSelected.selected">{{ termSelected.value }}
                            <span v-if="selectedCount > 1" class="comma">,</span>
                          </span>
                          <span>yr</span>
                        </span>
                      </span>
                    <img alt="Arrow" src="@/assets/icons/icon-drop-down-expand.svg"/>
                  </div>
                  <tippy
                    animateFill="false"
                    arrowType="round"
                    boundary="window"
                    class="setDate"
                    distance="1"
                    hideOnClick="true"
                    interactive="true"
                    :appendTo="tableFilterAppend"
                    placement="bottom-center"
                    sticky="true"
                    theme="dropdown"
                    to="tableFilter"
                    trigger="click"
                    zIndex="99"
                  >
                    <div class="tippy-filter-table">
                      <div class="row">
                        <div class="col-12 p-0">
                          <span>Loan Term:</span>
                          <div class="form-check mt-3" v-for="term in loanTerms" :key="term.id">
                            <input type="checkbox" class="form-check-input"
                                   v-model="term.selected" name="loanTerm" :id="'loanTerm' + term.id"
                                   :value="term.value" @change="updateFilter"/>
                            <label :for="'loanTerm' + term.id" class="form-check-label">{{ term.value }} yr</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </tippy>
                </div>
                <div class="col-sm-4" id="rateSheetField">
                  <multiselect
                    v-model="details.rateSheetId"
                    id="rateSheetOptionsField"
                    class="full-width w-100 custom-select"
                    open-direction="bottom"
                    :options="multiSelectData.rateSheetIds"
                    :searchable="true"
                    :show-labels="false" @select="switchRateSheet"
                    placeholder="Rate sheet ID">
                  </multiselect>
                </div>
              </div>
            </div>
          </div>

          <!-- Banks Proposals -->
          <div class="pricer-col-body">
            <div class="alert alert-warning" role="alert" v-if="details.rateSheetId !== 'current'">
              You are using historical pricing from Rate Sheet ID {{ details.rateSheetId }}
            </div>
            <div class="alert alert-warning" role="alert" v-if="details.bps">
              You are using Control Your Price
            </div>
            <div class="border-light">
              <!-- Bank tabs -->
              <div class="pricer-tabs" v-if="!isGranted('ROLE_IREFI_AGENT') && !isGranted('ROLE_SD_COMP_PLAN')">
                <div class="row w-100 m-auto">
                  <div class="col p-0" v-for="(option, index) in tabs" :key="index">
                    <div class="pricer-tab" :class="{ active: pricerTab.name === option.name }" @click="changeTab(option)">
                      <div class="pricer-tab-title">{{ option.name }}</div>
                      <div class="pricer-tab-subtitle" v-if="!isGranted('ROLE_COMPANY_LEADS_SINGLE') || !isGranted('ROLE_COMPANY_LEADS_MERGE')">
                        Comp: {{ option.comp }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Spinner for preload bank proposals -->
              <page-spinner v-if="ui.preloadData" class="mb-3"/>
              <div class="mb-5 mt-5 text-center" v-else-if="!ui.preloadData && errorResponse">
                {{ errorResponse }}
              </div>
              <div class="alert-warning p-3" v-if="ui.changedData && bankProposals.length === 0">
                Parameters have been updated. Click "Price" to Refresh.
              </div>
              <!-- Bank's proposals -->
              <div class="row mb-2 w-100 m-auto" v-if="!ui.preloadData && !errorResponse && !ui.changedData">
                <pricer-config-tab
                  :tab-data="bankProposals"
                  class="p-0"
                  :loan-term="loanTermOptions"
                  :lock-term="lockOptions"
                  :property-type="details.propertyType"
                  :state="details.state"
                  :isVA="details.loanType === 'VA'"
                />
                <div class="alert_notification text-center" v-if="validationErr">
                  <b>The given data was invalid.</b>
                </div>
                <div class="alert_notification text-center" v-if="smWrong">
                  <b>Something went wrong...</b>
                  <p class="mt-1 mb-0">
                    UWM has made a change to the layout of their rate sheet.  We are working on updating the system to match.
                    <br>
                    If you need immediate help, click on Get Quick Help.
<!--                    IT just received notification about this-->
<!--                    error - most likely we just downloaded new rates sheet-->
<!--                    by UWM and looks like there are some new adjustments-->
<!--                    or programs added or removed, so we have to update-->
<!--                    this Auto Quote tool to get data from correct rows &-->
<!--                    columns.-->
<!--                    <b>This should be fixed soon (~15-30 minutes).</b>-->
<!--                    Sorry for the inconvenience.-->
                  </p>
                </div>
                <div class="alert_notification text-center" v-if="emptyErr">
                  <b>No available programs</b>
                  <p class="mt-2">We didn't found any supported programs for specified parameters of loan.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Multiselect from "vue-multiselect";
import PageSpinner from "../../components/pageSpinner";
import PricerConfigTab from "./includes/PricerConfigTab";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import axios from "axios";
import BaseButton from '../../components/BaseButton.vue';

const currencyMask = createNumberMask({
  prefix: '',
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
});
export default {
  name: "CorrPricer",
  components: {PricerConfigTab, Multiselect, PageSpinner, BaseButton},
  data() {
    return {
      mask: currencyMask,
      details: {
        loanType: '',
        purpose: "",
        loanAmount: 350000,
        secondLoanAmount: 0,
        propertyValue: 450000,
        ltv: 77.78,
        fico: 740,
        monthsOfReserves: 0,
        commitmentPeriod: {value: 30, label: '30 days'},
        zipCode: "92618",
        state: "CA",
        county: "ORANGE",
        propertyType: "",
        occupancyType: "",
        attachmentType: "",
        numberOfUnits: 1,
        VAUseType: '',
        fundingFeeType: '',
        mortgageInsuranceType: null,
        rateSheetId: 'current',
        debtToIncome: 'No',
        debtToIncomeOptions: ['Yes', 'No'],
        exactRateType: '',
        exactRateTypeOptions: [],
        targetRate: null,
        isFirstTimeHomeBuyer: false,
      },
      multiSelectData: {
        loanTypes: [],
        propertyTypes: [],
        countyOptions: [],
        miTypeOptions: [],
        occupancyTypes: [],
        purposes: [],
        attachmentTypes: [],
        terms: [],
        lockPeriod: [],
        VAUseTypeOptions: [],
        fundingFeeTypeOptions: [],
        rateSheetIds: [],
      },
      errorMsg: "",
      ui: {
        loading: false,
        preloadData: false,
        loadingState: false,
        changedData: false,
        branchId: null,
      },
      bankProposals: [],
      loanTerms: [
        {id: 1, value: 30, selected: true},
        {id: 2, value: 20, selected: false},
        {id: 3, value: 15, selected: false},
        {id: 4, value: 10, selected: false},
      ],
      lockPeriod: [
        {value: 15, label: '15 days'},
        {value: 30, label: '30 days'},
        {value: 45, label: '45 days'},
        {value: 60, label: '60 days'},
      ],
      termTypes: [
        {id: 1, value: "Fixed"},
        {id: 2, value: "Unfixed"},
      ],
      eligibilitySelected: "",
      eligibilityList: [{id: 1, value: "All"}],
      termTypeSelected: "",
      pricerTab: {},
      tabs: [],
      errorResponse: "",
      loanTermOptions: [30],
      lockOptions: [30],
      errors: [],
      validationErr: false,
      emptyErr: false,
      smWrong: false,
      agentsPartners: [],
      selectedAgent: null,
      isCYP: false,
      bps: null,
    };
  },
  computed: {
    selectedCount() {
      return this.loanTerms.filter((el) => el.selected === true).length;
    },
    selectedLockPeriod() {
      return this.lockPeriod.filter((el) => el.selected === true).length;
    },
    showFundingFeeInput() {
      let purpose = this.details.purpose
      return (
        purpose === "VA Cash Out - Type I" ||
        purpose === "VA Cash Out - Type II" ||
        purpose === "VA IRRRL" || (purpose === 'Purchase' && this.details.loanType === 'VA')
      );
    },
    showVAUseTypeField() {
      let purpose = this.details.purpose
      return (
        this.details.loanType === 'VA'
        && purpose === 'Purchase' || purpose === "VA Cash Out - Type I" || purpose === "VA Cash Out - Type II"
      );
    },
    showAttachmentType() {
      let type = this.details.propertyType;
      return (
        type === "Condominium" ||
        type === "Site Condo" ||
        type === "Manufactured Condo Single Wide" ||
        type === "Manufactured Condo Multi Wide"
      );
    },
    ltv() {
      let loanAmount = this.details.loanAmount
      let propertyValue = this.details.propertyValue

      if (typeof loanAmount === 'string' || loanAmount instanceof String) {
        loanAmount = loanAmount.replaceAll(',', '')
      }

      if (typeof propertyValue === 'string' || propertyValue instanceof String) {
        propertyValue = propertyValue.replaceAll(',', '')
      }

      return (loanAmount / propertyValue) * 100;
    },
    cltv() {
      let loanAmount = this.details.loanAmount
      let secondAmount = this.details.secondLoanAmount
      let propertyValue = this.details.propertyValue

      if (typeof loanAmount === 'string' || loanAmount instanceof String) {
        loanAmount = loanAmount.replaceAll(',', '')
        loanAmount = Number.parseFloat(loanAmount)
      }

      if (typeof propertyValue === 'string' || propertyValue instanceof String) {
        propertyValue = propertyValue.replaceAll(',', '')
      }

      if (typeof secondAmount === 'string' || secondAmount instanceof String) {
        secondAmount = secondAmount.replaceAll(',', '')
        secondAmount = Number.parseFloat(secondAmount)
      }

      let amount = loanAmount + secondAmount
      return (amount / propertyValue) * 100;
    },
    getLtv() {
      return isNaN(Number.parseFloat(this.ltv).toFixed(2)) === true
        ? "0.00"
        : Number.parseFloat(this.ltv).toFixed(2);
    },
    getCLTV() {
      return isNaN(Number.parseFloat(this.cltv).toFixed(2)) === true
        ? "0.00"
        : Number.parseFloat(this.cltv).toFixed(2);
    },
  },
  methods: {
    getPartnersCompensation() {
      this.ui.loading = true;
      this.$http
        .get("/api/v1/corr-pricer/get-partners")
        .then((response) => {
          this.ui.loading = false;
          this.tabs = response.data.partners;
          this.getFormData();
          this.getRateSheetIds();
          this.ui.branchId = response.data.branchId
        })
        .catch(() => {
          this.ui.loading = false;
        });
    },
    getFormData() {
      axios
        .get(process.env.VUE_APP_V_CRM_URL + "/api/v1/aqt/uwm/get-form-data", {
          params: {
            zip: this.details.zipCode,
          },
        })
        .then((response) => {
          this.multiSelectData.loanTypes = response.data.loanTypeOptions;
          this.details.loanType = this.multiSelectData.loanTypes[0];
          this.toggleLoanType(this.details.loanType)
          this.multiSelectData.propertyTypes = response.data.propertyTypeOptions;
          this.multiSelectData.attachmentTypes = response.data.propertyTypeAttachmentOptions;
          this.multiSelectData.occupancyTypes = response.data.propertyUseOptions;
          this.multiSelectData.miTypeOptions = response.data.miTypeOptions;
          this.multiSelectData.fundingFeeTypeOptions = response.data.fundingFeeTypeOptions;
          this.multiSelectData.VAUseTypeOptions = response.data.VAUseTypeOptions;
          this.details.propertyType = this.multiSelectData.propertyTypes[0];
          this.details.occupancyType = this.multiSelectData.occupancyTypes[0];
          this.details.purpose = this.multiSelectData.purposes[0];
          this.details.exactRateTypeOptions = response.data.exactRateTypeOptions;

          this.multiSelectData.countyOptions = response.data.counties;
          this.details.state = response.data.state;
          this.details.county = response.data.county;

          if (this.tabs.length) {
            this.changeTab(this.tabs[0]);
          }
        })
        .catch(() => {});
    },
    getQuote() {
      this.ui.changedData = false;
      if (!this.pricerTab) {
        return 0;
      }
      this.bankProposals = []
      this.ui.preloadData = true;
      this.emptyErr = false;
      this.smWrong = false;
      this.validationErr = false;
      this.errors = []

      let formData = new FormData();

      formData.append("loanType", this.details.loanType);
      formData.append("loanPurpose", this.details.purpose);
      formData.append("loanAmount", this.details.loanAmount.replaceAll(',', ''));
      formData.append("secondLoanAmount", this.details.secondLoanAmount.replaceAll(',', ''));
      formData.append("propertyValue", this.details.propertyValue.replaceAll(',', ''));
      formData.append("propertyUse", this.details.occupancyType);
      formData.append("propertyType", this.details.propertyType);
      formData.append("attachmentType", this.details.attachmentType);
      formData.append("numberOfUnits", this.details.numberOfUnits);
      formData.append("propertyState", this.details.state);
      formData.append("zipCode", this.details.zipCode);
      formData.append("county", this.details.county);
      formData.append("creditScore", this.details.fico);
      formData.append("monthsOfReserves", this.details.monthsOfReserves);
      formData.append("compensation", this.pricerTab.comp);
      formData.append("minCompensation", this.pricerTab.minComp);
      formData.append("maxCompensation", this.pricerTab.maxComp);
      formData.append("VAUseType", this.details.VAUseType ? this.details.VAUseType.value : null);
      formData.append("fundingFeeType", this.details.fundingFeeType ? this.details.fundingFeeType.value : null);
      formData.append("mortgageInsuranceType", this.details.mortgageInsuranceType);
      formData.append("rateSheetId", this.details.rateSheetId);
      formData.append("commitmentPeriod", this.details.commitmentPeriod.value);
      formData.append("exactRateType", this.details.exactRateType);
      formData.append("targetRate", this.details.targetRate);
      formData.append("debtToIncome", this.details.debtToIncome);

      if (this.details.bps) {
        formData.append("bps", this.details.bps);
      }

      axios.post(process.env.VUE_APP_V_CRM_URL + `/api/v1/aqt/uwm/get-quote`, formData)
        .then((response) => {
          this.ui.preloadData = false;

          let programs = response.data.programs;
          for (const programName in programs) {
            programs[programName]["showOptions"] = false;
            programs[programName]["visible"] = true;
          }
          this.bankProposals = programs;

          this.errorResponse = null;
          this.details.rateSheetId = response.data.rateSheetId;
          this.ui.changedData = false

          if (this.bankProposals.length === 0) {
            this.emptyErr = true;
          }
        })
        .catch((error) => {
          this.bankProposals = [];
          this.ui.preloadData = false;
          this.errors = error.response.data.errors

          this.ui.changedData = false;

          if (error.response.status === 422) {
            this.validationErr = true;
          }
          if (error.response.status === 400) {
            this.smWrong = true;
          }
        });
    },
    getAgents() {
      this.$http.get('/api/v1/corr-pricer/get-agents-partners')
        .then(response => this.agentsPartners = response.data.agentsPartners)
    },
    togglePartners(tab) {
      this.tabs = tab.partners
      this.pricerTab = this.tabs[0];
      this.changedData()
      this.getQuote()
    },
    changeTab(tab) {
      this.pricerTab = tab;
      this.getQuote();
    },
    tableFilterAppend() {
      return document.getElementById("table-filter");
    },
    tableFilterLockPeriod() {
      return document.getElementById("lock-days");
    },
    filterTermAppend() {
      return document.getElementById("term-type");
    },
    filterEligibilityAppend() {
      return document.getElementById("filterEligibility");
    },
    getState() {
      let zipCode = this.details.zipCode;
      this.errorMsg = "";
      if (zipCode.toString().length < 5 || zipCode.toString().length > 5) {
        this.errorMsg =
          "This field must be formatted as a Zip Code. E.g: 48152";
        return 0;
      }
      axios
        .get(
          process.env.VUE_APP_V_CRM_URL +
          `/api/v1/us_zips/get-state-county/${zipCode}`
        )
        .then((response) => {
          this.multiSelectData.countyOptions = response.data.counties;
          this.details.state = response.data.state;
          this.details.county = response.data.county;
        })
        .catch(() => {
        });
    },
    updateFilter($event) {
      this.changedData()
      if ($event.target.name === "loanTerm") {
        if ($event.target.checked) {
          this.loanTermOptions.push(parseInt($event.target.value));
        } else {
          let index = this.loanTermOptions.indexOf(
            parseInt($event.target.value)
          );
          if (index !== -1) {
            this.loanTermOptions.splice(index, 1);
          }
        }
      }

      if ($event.target.name === "lockPeriod") {
        if ($event.target.checked) {
          this.lockOptions.push(parseInt($event.target.value));
        } else {
          let index = this.lockOptions.indexOf(parseInt($event.target.value));
          if (index !== -1) {
            this.lockOptions.splice(index, 1);
          }
        }
      }
    },
    getRateSheetIds() {
      axios.get(process.env.VUE_APP_V_CRM_URL + `/api/v1/aqt/uwm/get-rate-sheet-id-list`)
        .then((response) => {
          this.multiSelectData.rateSheetIds = response.data.rateSheetIds
        })
        .catch((e) => {
          console.log(e)
        })
    },
    switchRateSheet(val) {
      this.details.rateSheetId = val
      this.changedData()
      this.getQuote()
    },
    toggleLoanType(val) {
      this.changedData()
      this.bankProposals = []
      if (val === 'VA') {
        this.multiSelectData.purposes = ['Purchase', 'VA Cash Out - Type I', 'VA Cash Out - Type II', 'VA IRRRL'];
      } else {
        this.multiSelectData.purposes = ['Purchase', 'Refinance Rate/Term', 'Refinance Cash Out'];
      }
      this.details.loanType = val
      this.details.purpose = this.multiSelectData.purposes[0]
    },
    changedData() {
      this.ui.changedData = true;
      this.bankProposals = []
    },
    updateLTV(e, fieldName) {
      let loanAmount = this.details.loanAmount
      let propertyValue = this.details.propertyValue

      if (typeof loanAmount === 'string' || loanAmount instanceof String) {
        loanAmount = loanAmount.replaceAll(',', '')
      }

      if (typeof propertyValue === 'string' || propertyValue instanceof String) {
        propertyValue = propertyValue.replaceAll(',', '')
      }

      if (fieldName === 'loanAmount' || fieldName === 'propertyValue') {
        console.log(1)
        this.details.ltv = Number.parseFloat((loanAmount / propertyValue) * 100).toFixed(2);
        return
      }

      if (fieldName === 'ltv') {
        console.log(2)
        this.details.loanAmount = propertyValue * (this.details.ltv / 100)
      }
    },
    sendHelpRequest() {
      let formData = new FormData()
      formData.append('agentName', this.$store.getters.getFullName)
      formData.append('agentPhone', this.$store.getters.getPhone)
      formData.append('agentEmail', this.$store.getters.getEmail)

      this.$http.post('https://vision-crm.com/hooks/v1/send-help-request', formData)
        .then(() => this.pushAlert('success', 'Your request has been sent. Correspondent Support will contact you ASAP.', 5000))
        .catch(() => this.pushAlert('error', 'Something went wrong. Try again later please.'))
    }
  },
  mounted() {
    this.getPartnersCompensation();
    this.getAgents();
  },
  watch: {
    details: {
      deep: true,
      handler() {}
    }
  }
};
</script>

<style lang="scss" scoped>
.page-content {
  padding: 0;
}

.error {
  font-size: 12px;
  color: red;
}

.container-fluid {
  @media (max-width: 568px) {
    padding: 0;
  }
}

.row {
  @media (max-width: 568px) {
    width: 100%;
    margin: 0 auto;
  }
}

.pricer {
  &-col {
    min-height: 90vh;

    &:first-child {
      border-right: 1px solid #e7e8e8;
    }

    &-title {
      color: #000000;
      font-size: 15px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 21px;
      padding: 17px 18px;
      border-bottom: 1px solid #e7e8e8;
    }

    &-body {
      padding: 18px;
    }
  }
}

::v-deep {
  .multiselect {
    min-height: 32px;
    max-height: 32px;
    min-width: 100%;

    &.input-filled {
      .multiselect__tags {
        border: 1px solid #d4d4d4;
        background-color: #f3f3f3;
      }

      .multiselect__single {
        background: none;
        color: #000;
      }
    }

    width: fit-content;
    @media screen and (max-width: 568px) {
      max-width: 50%;
      min-width: 100%;
    }

    &.selected {
      .multiselect__tags {
        background-color: #f3f3f4;
      }

      .multiselect__single {
        background-color: #f3f3f4;
        color: #000000;
        font-size: 14px;
        letter-spacing: 0;
      }

      input {
        background-color: #f3f3f4;
      }
    }

    &.multiselect--active {
      .multiselect__tags {
        transition: all 0.3s;
        border: 1px solid rgba(38, 111, 229, 0.5);
        box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
      }
    }

    &__tags {
      min-height: 32px;
      max-height: 32px;
      padding: 5px 30px 0 8px;
      transition: all 0.3s;
      border-radius: 4px;

      &:hover {
        border: 1px solid rgba(38, 111, 229, 0.2);
        box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
      }
    }

    &__select {
      min-height: 36px;
      max-height: 36px;

      &:before {
        top: 5%;
        position: relative;
        right: 0;
        color: #999;
        border: solid rgba(0, 0, 0, 0.46);
        margin-top: 4px;
        border-width: 0px 1px 1px 0;
        display: inline-block;
        padding: 3px;
        content: "";
        transform: rotate(44deg);
      }
    }

    &__single {
      color: rgba(0, 0, 0, 0.46);
      padding-left: 0;

      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }

    &__option {
      padding: 10px 12px;
      min-height: 36px;
      transition: all 0.3s;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }

      &--highlight {
        background: #f3f3f3;
        line-height: 20px;
        font-size: 14px;
        color: #000;
        @media screen and (max-width: 568px) {
          font-size: 9px;
        }
      }
    }
  }

  .multiselect.normal {
    min-height: 36px;
    max-height: 36px;
    min-width: 100%;
    width: fit-content;
    background: none;
    @media screen and (max-width: 568px) {
      max-width: 50%;
      min-width: 100%;
    }

    &.selected {
      .multiselect__tags {
        background-color: #f3f3f4;
      }

      .multiselect__single {
        background-color: #f3f3f4;
        color: #000000;
        font-size: 14px;
        letter-spacing: 0;
      }

      input {
        background-color: #f3f3f4;
      }
    }

    &.multiselect--active {
      .multiselect__tags {
        transition: all 0.3s;
        border: 1px solid rgba(38, 111, 229, 0.5);
        box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
      }
    }

    .multiselect__placeholder {
      padding: 0;
    }

    .multiselect__tags {
      min-height: 36px;
      max-height: 36px;
      padding: 9px 30px 0 8px;
      transition: all 0.3s;
      border-radius: 8px;
      border: 1px solid #d4d4d4;

      &:hover {
        border: 1px solid rgba(38, 111, 229, 0.2);
        box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
      }
    }

    .multiselect__select {
      min-height: 36px;
      max-height: 36px;
      background: none;

      &:before {
        top: 5%;
        position: relative;
        right: 0;
        color: #999;
        border: solid rgba(0, 0, 0, 0.46);
        margin-top: 4px;
        border-width: 0px 1px 1px 0;
        display: inline-block;
        padding: 3px;
        content: "";
        transform: rotate(44deg);
      }
    }

    .multiselect__single {
      color: rgba(0, 0, 0, 0.46);
      padding-left: 0;

      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }

    .multiselect__option {
      padding: 10px 12px;
      min-height: 36px;
      transition: all 0.3s;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }

      &--highlight {
        background: #f3f3f3;
        line-height: 20px;
        font-size: 14px;
        color: #000;
        @media screen and (max-width: 568px) {
          font-size: 9px;
        }
      }
    }
  }
}

.border-light {
  border: 1px solid #e2e2e2 !important;
}

.pricer-tab {
  position: relative;
  padding: 24px 24px 40px 24px;
  border: 1px solid #e2e2e2;
  background-color: #f6f6f6;
  cursor: pointer;
  transition: all 0.4s;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: "";
    width: 0;
    height: 3px;
    transition: all 0.6s;
  }

  &.active {
    background: white;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      content: "";
      width: 100%;
      height: 3px;
      background-color: #017aff;
    }

    &-title {
      color: #000000;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 16px;
    }
  }

  &:hover {
    background: white;

    &-title {
      font-weight: 600;
      color: rgba(0, 0, 0, 1);
    }
  }

  &-title {
    color: rgba(0, 0, 0, 0.7);
    font-size: 16px;
    letter-spacing: 0;
    line-height: 16px;
    margin-bottom: 12px;
  }

  &-subtitle {
    color: rgba(0, 0, 0, 0.46);
    font-size: 12px;
    letter-spacing: 0;
    line-height: 12px;
  }
}

#table-filter,
#lock-days,
#term-type,
#filterEligibility {
  position: relative;

  .tippy-dropdown {
    padding-top: 1px;
    padding-left: 8px;

    img {
      background: white;
      opacity: 1;
    }

    span {
      opacity: 1;
      color: rgba($color: #000000, $alpha: 0.46);
      margin: 0;

      span {
        opacity: 1;
        color: #000000;
        margin: 0;

        &:last-child {
          span {
            &:last-child {
              display: inline-block;
            }
          }
        }

        span {
          opacity: 1;
          color: #000000;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 12px;
          margin-left: 4px;

          &:last-child {
            display: none;
          }
        }
      }
    }
  }

  &::v-deep {
    .tippy-popper {
      width: 100%;

      .tippy-tooltip {
        width: 100%;
        max-width: 100% !important;
      }
    }
  }
}

// #lock-days {
//   .comma {
//     margin-left: 1px !important;
//   }
//   .tippy-dropdown {
//     span {
//       span {
//         span {
//           margin-left: 0 !important;
//         }
//       }
//     }
//   }
// }
.form-group {
  @media (max-width: 568px) {
    margin: 0;
  }

  .input-with-icon {
    input {
      padding-left: 20px;
    }
  }

  input {
    height: 32px;
    min-height: 32px;
    border-radius: 4px;
    padding-left: 8px;
  }

  span {
    &.icon {
      top: 9px;
      color: rgba(0, 0, 0, 0.46);
      font-size: 14px;
      letter-spacing: 0;
      line-height: 14px;
    }
  }

  label {
    color: rgba(0, 0, 0, 0.7);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    text-align: end;
    margin-top: 2px;
  }

  .error {
    margin-top: 4px;
  }
}

.btn {
  &.bg-green {
    height: 36px;
  }
}

.report-type {
  display: none;

  @media (min-width: 568px) {
    display: inline-block;
  }
}

.comma {
  display: inline-block !important;
  margin: 0 0 0 -3px !important;
}

.alert_notification {
  width: 95%;
  margin: 15px auto;
}

#rateSheetField,
#agentsField {
  &::v-deep {
    .multiselect {
      &__tags {
        height: 36px;
        min-height: 36px;
        border-radius: 8px;
        padding: 11px 30px 0 8px;
      }

      &__single {
        font-size: 12px;
        letter-spacing: 0;
        line-height: 12px;
        margin: 0;
      }
    }
  }
}

#agentsField {
  margin-right: 10px;

  &::v-deep {
    .multiselect {
      min-width: 200px;

      &__placeholder {
        margin: 0;
        padding: 0;
      }

      &__tags {
        height: 36px;
        min-height: 36px;
        border-radius: 8px;
        padding: 8px 30px 0 8px;
      }
    }
  }
}
</style>
