<template>
  <div class="modal modal-center">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>Adjustments</h3>
        <button class="btn btn-control" @click="$emit('close')">
          <img src="@/assets/icons/icon-remove.svg" alt="">
        </button>
      </div>
      <div class="modal-body pb-0">
        <h5>Loan Details</h5>
        <div class="modal-body-container border-bottom-0">
          <table class="table">
            <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="fundingFee > 0">
              <td>{{ fundingFeeType === 'FINANCED' ? 'Funding Fee' : 'Upfront MIP' }}</td>
              <td></td>
              <td>{{ convertToCurrency(fundingFee) }}</td>
            </tr>
            <tr>
              <td>Loan Amount</td>
              <td></td>
              <td>{{ convertToCurrency(loanAmount) }}</td>
            </tr>
            <tr>
              <td>Interest Rate</td>
              <td></td>
              <td>{{ interestRate }}%</td>
            </tr>
            </tbody>
          </table>
        </div>
        <h5>Pricing  breakdown</h5>
        <div class="modal-body-container border-bottom-0 mb-0 pb-0">
          <table class="table">
            <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">%</th>
              <th scope="col">Amount</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(adj, index) in tableData" :key="index">
              <td>{{ adj.name }}</td>
              <td>{{ convertToNumberFormat(adj.adjustment) }}</td>
              <td>{{ convertToCurrency(adj.amount) }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-cancel" @click="$emit('close')">
            Close
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdjustmentsModal',
  props: {
    breakdown: { type: Object },
    basePrice: { type: String },
    loanAmount: { type: Number },
    fundingFee: { type: Number },
    fundingFeeType: { type: String },
    interestRate: { type: String },
  },
  data() {
    return {
      tableData: [],
      modalBasePrice: '',
      modalBreakDown: {}
    }
  },
  methods: {
    filterAdj() {
      const breakDownArr = this.modalBreakDown[Number.parseFloat(this.modalBasePrice)];
      let customAdjustments = this.isGranted('ROLE_IREFI_AGENT') || this.isGranted('ROLE_SD_COMP_PLAN')

      breakDownArr.forEach((item) => {
        if (customAdjustments && item.name === 'Final Price') {
          return;
        }
        if (customAdjustments && item.name === 'Compensation') {
          this.tableData[0].adjustment += item.adjustment
          this.tableData[0].amount += item.amount
          return;
        }
        if (customAdjustments && item.name === 'Final Price After Comp.') {
          item.name = 'Final Price:'
        }

        this.tableData.push(item)
      })
    }
  },
  beforeMount() {
    this.tableData = [];
    this.modalBreakDown = JSON.parse(JSON.stringify(this.breakdown));
    this.modalBasePrice = this.basePrice;
    this.filterAdj();
  }
}
</script>

<style lang="scss" scoped>
.modal {
  &-body {
    height: 100%;
    overflow-y: auto;
  }
  &-block {
    height: 70% !important;
    margin: 100px auto!important;
    overflow-y: hidden !important;

    p {
      color: #000000;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 25px;
    }
    .modal-footer {
      bottom: unset;
    }
  }
}
</style>
